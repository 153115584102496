<!--费率信息-->
<template>
  <div class="page">
    <Tables
      ref="xTable"
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :searchFormData="searchFormData"
      :showRefresh="true"
      :showSearch="true"
      :showAdd="$hasAccess('device.type/add')"
      addName="新增类型"
      @getList="getList"
      @clearSearch="clearSearch"
      @addItemInTable="addOne"
    >

      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-input
          v-model="searchFormData.type_name"
          size="small"
          clearable
          placeholder="请类型查询"
        ></el-input>
      </el-form-item>

      <!--表格内容-->
      <vxe-table-column
        slot="table-item"
        field="type_name"
        title="类型名称"
        align="center"
        min-width="60px"
      />
      <vxe-table-column
              slot="table-item"
              field="type"
              title="终端类型"
              align="center"
              min-width="70px"
      >
        <template v-slot="{ row }">
          <span>{{types.device_type[row.type]||'未知类型'}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
              slot="table-item"
              field="ele_interface"
              title="充电接口"
              align="center"
              min-width="70px"
      />
      <vxe-table-column
        slot="table-item"
        field="rated_power"
        title="额定功率"
        align="center"
        min-width="70px"
      />
      <vxe-table-column
        slot="table-item"
        field="rated_v"
        title="额定电压"
        align="center"
        min-width="70px"
      />
      <vxe-table-column
              slot="table-item"
              field="rated_out"
              title="额定输出"
              align="center"
              min-width="70px"
      />
      <vxe-table-column
              slot="table-item"
              field="rated_current"
              title="额定电流"
              align="center"
              min-width="70px"
      />
      <vxe-table-column
        slot="table-item"
        title="操作"
        align="center"
        width="200"
      >
        <template v-slot="{ row }">
          <el-button
                  size="small"
                  plain
                  @click="editOne(row)"
                  v-if="$hasAccess('device.type/edit')"
          >编辑
          </el-button>
          <el-button
                  size="small"
                  plain
                  @click="deleteOne(row)"
                  v-if="$hasAccess('device.type/delete')"
          >删除
          </el-button>
        </template>
      </vxe-table-column>
    </Tables>

    <!-- 新增 -->
    <el-dialog
      title="新增类型"
      :visible.sync="addDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="800px"
    >

      <el-form
        :model="addDialogFormData"
        :rules="rules"
        size="mini"
        ref="addForm"
        label-width="160px"
        class="form"
      >
        <el-row>
          <el-col
            :xs="24"
            :sm="10"
          >
            <el-form-item
              label="类型名称"
              prop="type_name"
            >
              <el-input
                v-model="addDialogFormData.type_name"
                style="width: 100%;"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item
                    label="充电接口"
                    prop="ele_interface"
            >
              <el-input
                      v-model="addDialogFormData.ele_interface"
                      style="width: 100%;"
                      size="small"
              ></el-input>
            </el-form-item>
            <el-form-item
                    label="终端类型"
                    prop="type"
            >
              <el-select v-model="addDialogFormData.type" placeholder="请选择类型查询" clearable size="small" class="from-item-width">
                <el-option label="交流慢充" :value="10">
                </el-option>
                <el-option label="直流快充" :value="20">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="额定输出"
              prop="rated_out"
              v-if="addDialogFormData.type==10"
            >
              <el-input
                      v-model="addDialogFormData.rated_out"
                      style="width: 100%;"
                      size="small"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="额定电流"
              prop="rated_current"
              v-if="addDialogFormData.type==10"
            >
              <el-input
                      v-model="addDialogFormData.rated_current"
                      style="width: 100%;"
                      size="small"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="额定功率"
              prop="rated_power"
              v-if="addDialogFormData.type==20"
            >
              <el-input
                      v-model="addDialogFormData.rated_power"
                      style="width: 100%;"
                      size="small"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="额定电压"
              prop="rated_v"
              v-if="addDialogFormData.type==20"
            >
              <el-input
                      v-model="addDialogFormData.rated_v"
                      style="width: 100%;"
                      size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-check"
          @click="saveAdd()"
          v-if="$hasAccess('device.type/add')"
        >保 存
        </el-button>
        <el-button
          size="small"
          plain
          icon="el-icon-close"
          @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog>

    <!-- 编辑 -->
    <el-dialog
      title="编辑设备类型"
      :visible.sync="editDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="800px"
    >

      <el-form
        :model="editDialogFormData"
        :rules="rules"
        size="mini"
        ref="editForm"
        label-width="160px"
        class="form"
      >
        <el-row>
          <el-col
            :xs="24"
            :sm="10"
          >
            <el-form-item
              label="类型名称"
              prop="type_name"
            >
              <el-input
                v-model="editDialogFormData.type_name"
                style="width: 100%;"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item
                    label="充电接口"
                    prop="ele_interface"
            >
              <el-input
                      v-model="editDialogFormData.ele_interface"
                      style="width: 100%;"
                      size="small"
              ></el-input>
            </el-form-item>
            <el-form-item
                    label="终端类型"
                    prop="short_one_hour"
            >
              <el-select v-model="editDialogFormData.type"  clearable size="small" class="from-item-width">
                <el-option label="交流慢充" :value="10">
                </el-option>
                <el-option label="直流快充" :value="20">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
                    label="额定输出"
                    prop="rated_out"
                    v-if="editDialogFormData.type==10"
            >
              <el-input
                      v-model="editDialogFormData.rated_out"
                      style="width: 100%;"
                      size="small"
              ></el-input>
            </el-form-item>
            <el-form-item
                    label="额定电流"
                    prop="rated_current"
                    v-if="editDialogFormData.type==10"
            >
              <el-input
                      v-model="editDialogFormData.rated_current"
                      style="width: 100%;"
                      size="small"
              ></el-input>
            </el-form-item>
            <el-form-item
                    label="额定功率"
                    prop="rated_power"
                    v-if="editDialogFormData.type==20"
            >
              <el-input
                      v-model="editDialogFormData.rated_power"
                      style="width: 100%;"
                      size="small"
              ></el-input>
            </el-form-item>
            <el-form-item
                    label="额定电压"
                    prop="rated_v"
                    v-if="editDialogFormData.type==20"
            >
              <el-input
                      v-model="editDialogFormData.rated_v"
                      style="width: 100%;"
                      size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-check"
          @click="saveEdit()"
          :disabled="editDisabled"
          v-if="$hasAccess('device.type/edit')"
        >保 存 编 辑
        </el-button>
        <el-button
          size="small"
          plain
          icon="el-icon-close"
          @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from '@/service/api'
import Tables from '@/components/tables'

export default {
  name: 'device-type-list',
  components: {
    Tables
  },
  data () {
    return {
      // table信息
      tableName: '设备类型信息',
      isLoading: false,
      tableData: [],
      totalPage: 0,
      searchFormData: {},
      // 新增
      addDialogShow: false,
      addDialogFormData: {},
      // 编辑
      editDialogShow: false,
      editDialogFormData: {},
      editDialogFormOriData: {},
      editNeedData: ['type_name', 'ele_interface', 'rated_out',  'rated_current', 'rated_power',  'rated_v',  'type'],
      // 规则
      rules: {
        type_name: [
          { required: true, message: '请输类型名称', trigger: 'change' }
        ],
        ele_interface: [
          { required: true, message: '请输入充电接口', trigger: 'change' }
        ],
        rated_out: [
          { required: true, message: '请输入额定输出', trigger: 'change' }
        ],
        rated_current: [
          { required: true, message: '请输入额定电流', trigger: 'change' }
        ],
        rated_power: [
          { required: true, message: '请输入额定功率', trigger: 'change' }
        ],
        rated_v: [
          { required: true, message: '请输入额定电压', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ]
      },// 类型信息
      types: {
        device_type: {
          10: "交流慢充",
          20: "直流快充"
        }
      },
    }
  },
  computed: {
    editDisabled () {
      for (let item of this.editNeedData) {
        if (this.editDialogFormData[item] != this.editDialogFormOriData[item]) {
          return false
        }
      }
      return true
    }
  },
  mounted () {

  },
  methods: {
    // 表格列表
    async getList (currentPage, pageSize) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      this.isLoading = true
      const searchData = this.searchFormData
      const res = await API.Device.GetTypeList(params, searchData)
      this.tableData = res.data
      this.totalPage = res.total
      this.isLoading = false
    },
    // 重置搜索
    clearSearch () {
      this.searchFormData = {
        type_id: ''
      }
    },
    // 重置form
    resetFormData () {
      this.addDialogFormData = {}
      this.editDialogFormData = {}
      if (this.$refs.addForm) {
        this.$refs.addForm.resetFields()
      }
      if (this.$refs.editForm) {
        this.$refs.editForm.resetFields()
      }
    },
    // 新增
    addOne () {
      this.resetFormData()
      this.addDialogShow = true
    },
    // 编辑
    async editOne (row) {
      this.resetFormData()
      const params = {
        token: this.$store.state.user.token,
        type_id: row.type_id
      }
      const res = await API.Device.GetTypeDetail(params)
      this.editDialogFormData = res
      if (res) {
        const { type_id, type_name,type,ele_interface, rated_out,  rated_current, rated_power,  rated_v } = res
        this.editDialogFormData = { type_id, type_name,type,ele_interface, rated_out,  rated_current, rated_power,  rated_v }
        this.editDialogFormOriData = { type_id, type_name,type,ele_interface, rated_out,  rated_current, rated_power,  rated_v}
      }
      this.editDialogShow = true
    },
    // 关闭弹窗
    closeDialog () {
      this.addDialogShow = false
      this.editDialogShow = false
    },
    // 新增保存
    saveAdd () {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.addDialogFormData)
          }
          await API.Device.AddType(params)
          this.$notify({
            title: '成功',
            message: '新增成功',
            type: 'success'
          })
          this.closeDialog()
          this.getList(1, 10)
        }
      })
    },
    // 编辑保存
    saveEdit () {
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.editDialogFormData)
          }
          await API.Device.EditType(params)
          this.$notify({
            title: '成功',
            message: '编辑成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    },	// 删除设备
    deleteOne(row) {
      this.$confirm("确定要删除该类型吗", "提示", {
        cancelButtonClass: "btn-custom-cancel",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const params = {
          token: this.$store.state.user.token,
          type_id: row.type_id,
        };
        await this.$api.Device.DeleteType(params);
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
        });
        this.$refs.xTable.refreshTable();
      });
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
